.notificationDiv {
    background: var(--containerColor);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
}

.notificationDiv i {
    width: 30px;
    height: 30px;
    background: var(--lightColor);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--textHeading);
    margin-right: 10px;
}

.notificationDiv h5 {
    margin: 0;
    color: var(--textHeading);
    font-size: 17px !important;
    text-transform: capitalize;
}

.notificationDiv p {
    margin: 0;
    color: var(--textColor);
    font-size: 14px !important;
    font-weight: 300;
}

.noData {
    height: 55vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.noData i {
    font-size: 50px;
    color: var(--textHeading);
}

.noData p {
    font-weight: 300;
    font-size: 16px;
    color: var(--textHeading);
}