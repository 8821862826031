.myteamRank h5 {
    justify-content: center;
}

.myteamRank img {
    width: 60%;
    margin-top: 25px;
}

.myteamRank {
    text-align: center;
    background: var(--lightColor);
}

.myteamTopDiv {
    background: var(--containerColor);
    border-radius: 20px;
    padding: 20px;
}

#nextrankWithIcon {
    display: flex;
    align-items: center;
}

#nextrankWithIcon i {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

#nextrankWithIcon h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.nextrank {
    background: var(--lightColor);
    height: 100%;
}

#nextrankWithIcon i,
#nextrankWithIcon h4 {
    color: var(--textHeading);
}

#nextrankWithIcon h4 span {
    color: orange;
}

#nextranktime {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 30px 0;
}

.nextrank p {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: var(--textColor);
}

#nextranktime h5 {
    color: var(--textHeading);
    margin: 0;
    margin-left: 20px;
}

#nextranktime>div>div {
    width: 100%;
    height: 20px;
    background: var(--containerColor);
    border-radius: 10px;
}

#nextranktime>div {
    display: flex;
    width: 100%;
    align-items: center;
}

#nextranktime i {
    color: var(--textHeading);
    font-size: 26px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

#teamUpline {
    background: var(--lightColor);
    padding: 20px;
    border-radius: 20px;
}

#teamUpline p {
    color: var(--textColor);
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    margin-bottom: 4px;
}

#teamUpline h5 {
    color: var(--textHeading);
    margin: 0;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
}

#teamUpline>div {
    display: flex;
    align-items: center;
}

#teamUpline>div i {
    color: var(--colorPrimary);
    font-size: 23px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.myteamLink {
    justify-content: flex-start;
    background: var(--lightColor);
    padding: 20px;
    margin: 0;
    margin-top: 20px;
}

.teamLevelCard>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.teamLevelCard {
    background: var(--containerColor);
    padding: 20px;
    border-radius: 20px;
    padding-left: 30px;
}

.teamLevel {
    margin-top: 20px;
}

.teamLevelCard p {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    color: var(--textColor);
}

.teamLevelCard h1 {
    font-size: 28px;
    font-weight: 600;
    font-style: normal;
    line-height: 20px;
    color: var(--colorPrimary);
    margin-top: 15px;
}

.teamLevelCard i {
    width: 60px;
    height: 60px;
    background: var(--colorPrimary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 25px;
    border: 14px solid var(--lightColor);
}

.teamGraph {
    background: var(--containerColor);
    padding: 20px;
    margin-top: 20px;
    border-radius: 20px;
}

#teamwithmail {
    display: flex;
    justify-content: space-between;
    position: relative;
}

#teamwithmail i {
    position: absolute;
    color: var(--textColor);
    font-size: 25px;
    top: 3px;
    right: 18px
}

.myteam {
    padding: 20px;
    border-radius: 20px;
    margin-top: 20px;
}

#teamwithmail>div {
    width: 350px;
}

#teamwithmail input {
    width: 100%;
    position: relative;
    justify-self: end;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-transform: uppercase;
    color: rgb(131, 131, 131);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-inline: 24px;
    height: 50px;
    border: 1px solid rgb(48, 47, 47);
    border-radius: 44px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    background: transparent;
    padding-right: 50px;
}

@media (max-width: 800px) {
    .myteamTopDiv {
        padding: 10px 0px;
    }

    #teamwithmail {
        flex-direction: column;
    }
}