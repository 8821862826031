.form_check_data {
  display: flex;
}

.form_check_data label {
  color: var(--colorPrimary);
  font-size: 14px;
  font-weight: 300;
}

.form_check_data input {
  accent-color: var(--colorPrimary);
  margin-right: 10px;
}

.registerInputError {
  text-align: left;
  font-size: 10px !important;
  color: rgb(241, 50, 50) !important;
  font-family: 300 !important;
  margin: 0 0 0 10px !important;
}

/* phone number */

.react-tel-input {
  position: relative;
  width: 100%;
  padding: 8px 20px;
  padding-right: 40px;
  border-radius: 25px;
  border: 1px solid rgba(128, 128, 128, 0.192);
  outline: none;
  margin-bottom: 10px;
  background: var(--lightColor) !important;
  color: var(--textHeading);
  font-size: 14px;
  height: 40px;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 12px !important;
  left: 13px !important;
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important; /* border-radius: 3px 0 0 3px; */ /* color: #fff; */
  height: 20px;
  width: 20px;
}
.react-tel-input .flag-dropdown:hover {
  background-color: transparent !important;
  border: none;
  height: 20px;
  width: 20px;
}
.react-tel-input .selected-flag{
  padding: 0px !important;
  /* width:38px!important; */
}
.react-tel-input .selected-flag .arrow {
  left: 0;
  border-top: 4px solid #fff !important;
}
.react-tel-input .selected-flag:focus, .react-tel-input .selected-flag:hover {
    background-color: transparent !important;
}
.react-tel-input .form-control {
  border: none !important;
  height: 28px !important;
  color: #fff !important;
  text-align: left !important;
  padding-left: 35px !important;
  width: 100% !important;
      padding: 6.5px 14px 8.5px 45px!important;
}
.react-tel-input .form-control:focus {
    box-shadow: none!important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #252525 !important;
  border-radius: 3px 0 0 0;
}
.selected-flag:hover {
  background-color: #252525 !important;
}
.react-tel-input .country-list .country {
  background: #252525;
  text-align: left;
}
.react-tel-input .country-list .country:hover {
  background-color: #252525;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #252525 !important;
  border-radius: 3px 0 0 0;
}
.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:hover {
  background-color: #252525;
}
.compulsory {
  font-size: 9px !important;
  color: var(--colorPrimary) !important;
  padding-left: 9px;
  text-align: left;
}
.react-tel-input .country-list .country:hover {
  background-color: #252525 !important;
}

.react-tel-input .special-label {
  display:none!important;
}
/* phone number end */

#sponsorLoading {
  width: 18px;
  height: 18px;
  border: 1px solid var(--colorPrimary);
  border-top-color: transparent;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}

#sponsorVerified {
  margin: 0;
  font-size: 10px;
  text-align: left;
  margin-left: 10px;
  color: rgb(34, 214, 34);
}

.registerSuccessDetails {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background: rgba(0, 0, 0, 0.85);
}

.registerSuccessDetails > div {
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
}

#successIcon {
  background: var(--colorPrimary);
  text-align: center;
  padding: 20px;
}

#successIcon i {
  font-size: 70px;
  margin: 0;
  color: #fff;
}

#successIcon h1 {
  color: #fff;
  margin: 0;
  font-size: 25px;
}

#successDetails {
  text-align: center;
  background: #fff;
  padding: 20px;
}

#successDetails > p {
  color: rgb(61, 60, 60);
  letter-spacing: 1px;
  margin: 0;
}

#successDetails p {
  color: rgb(61, 60, 60);
  margin: 0;
}

#successDetails > div {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

#successDetails > div > div {
  display: flex;
  gap: 10px;
  align-items: center;
}

#successDetails i {
  cursor: pointer;
}

#successDetails button {
  width: 50%;
  margin: auto;
  background: var(--btnBackground);
  border-radius: 25px;
  padding: 8px;
  color: #000;
  border: none;
  transition: all 0.3s;
}

#successDetails button:hover {
  transform: translateY(-5px);
}
@media (max-width: 360px) {
  .react-tel-input .form-control {
    width: 190px !important;
  }
}
