.StakingDetailsTimeDiv {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.StakingDetailsTimeDiv>div p {
    margin: 0;
    font-size: 14px;
    color: var(--textHeading);
}