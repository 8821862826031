.newsCard {
    padding: 20px;
    border-radius: 15px;
    background: var(--containerColor);
    height: 100%;
}

.newsCard img {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    margin-bottom: 15px;
    object-fit: cover;
    border-radius: 10px;
}

.newsCard>p {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    color: var(--textColor);
    margin-bottom: 9px;
}

.newsCard>h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--textHeading);
    margin-bottom: 12px;
}

.newsCard>h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--textColor);
}

.imgContainer {
    width: 100%;
    height: 180px;
    background: var(--lightColor);
    border-radius: 10px;
}