.liveRateDivInvestment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

}

.liveRateDivInvestment p {
  margin: 0;
}

.SelectWalletDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;

}

.selectWalletInner {
  /* border: 1px solid black; */
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.selectWalletInner p {
  margin: 10px 0;
}

.SelectWalletDiv img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.incomeSelectBox select {
  background: var(--containerColor);
  padding: 10px;
  border-radius: 20px;
  color: var(--textHeading);
  display: block;
}

.incomeSelectBox option {
  padding: 10px;
}

.incomeSelectBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap-reverse;
  gap: 20px;
}

.incomeSelectBox button {
  color: var(--colorPrimary);
  background: transparent;
  border: 1px solid var(--colorPrimary);
  padding: 10px 30px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.incomeSelectBox button i {
  font-size: 24px;
  display: flex;
  margin-left: 10px;
}

.filtersection {
  background: var(--containerColor);
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.filtersectionBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
}

.filtersection select {
  background: var(--containerColor);
  padding: 10px;
  border-radius: 5px;
  color: var(--textHeading);
  width: 100%;
  font-size: 14px;
  font-weight: 300;
}

.filtersection button {
  background: var(--colorPrimary);
  padding: 8px 30px;
  border-radius: 5px;
  border: transparent;
  width: 100%;
}

.addfundDiv {
  background-color: var(--containerColor);
  border-radius: 20px;
  width: 400px;
  padding: 20px;
  margin: auto;
  box-shadow: 0px 0px 2px 0px var(--textColor);
  max-width: 100%;
}

.addfundDiv label {
  width: 100%;
}

.addfundDiv h1 {
  color: var(--colorPrimary);
  font-size: 25px;
  text-align: center;
  margin-bottom: 20px;
}

.addfundDiv option {
  font-size: 18px;
}

.qrSection {
  width: 300px;
  background: var(--containerColor);
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  margin: auto;
  box-shadow: 0px 0px 2px 0px var(--textColor);
}

.qrSection p {
  color: var(--textHeading);
  font-size: 14px;
  font-weight: 300;
}

.qrSection h5 {
  font-size: 20px;
  color: var(--colorPrimary);
}

.qrDiv {
  background: white;
  padding: 10px;
  display: inline-block;
  margin: 10px 0;
  border-radius: 10px;
}

#qrAddress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.164);
  border-radius: 5px;
  padding: 5px;
  margin-top: 20px;
}

#qrAddress p {
  color: var(--textHeading);
  word-break: break-all;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
  margin-right: 10px;
  text-align: left;
}

#qrAddress i {
  cursor: copy;
  color: var(--colorPrimary);
  font-size: 20px;
  display: flex;
}

#timer {
  margin: 0;
  margin-bottom: 0px;
  color: var(--colorPrimary);
}

.holdingDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.holdingDiv p {
  flex: 1;
  text-align: center;
  border: 1px solid var(--colorPrimary);
  color: var(--colorPrimary);
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}

.holdingActiveItem {
  background: var(--colorPrimary) !important;
  color: black !important;
  font-weight: bold;
}

@media (max-width: 767px) {

  .incomeSelectBox button,
  .incomeSelectBox select {
    width: 100%;
  }
}