.claimDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: var(--containerColor);
  border-radius: 20px;
  flex-wrap: wrap;
  gap: 10px;
}
.claimDiv h1 {
  color: var(--textHeading);
  font-size: 24px;
}
.claimDiv p {
  color: var(--textHeading);
  font-size: 12px;
  margin: 0;
}
.claimDiv div {
  flex: 1;
  text-align: center;
  min-width: 150px;
}
.claimDiv div button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: var(--btnBackground);
  border: none;
  font-weight: 600;
  position: relative;
}

@media (max-width: 768px) {
  .claimDiv h1 {
    font-size: 16px;
  }
  .claimDiv p {
    font-size: 11px;
  }

  .claimDiv div button {
    width: 70px;
    height: auto;
    border-radius: 10px;
    background: var(--btnBackground);
    border: none;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
  }
}
